<template>
	<ej-page>
		<ej-mobile-header>
			<ej-toolbar>
				<ej-buttons>
					<ej-menu-button></ej-menu-button>
				</ej-buttons>

				<ej-title>{{ $route.meta.title }}</ej-title>

				<ej-buttons style="margin-left: auto">
					<ej-button
						@click="sortingToggle"
						:class="{ active: sortingOpened, 'mr-1': true }"
						v-if="_.get($store.state, `${module}.sorting.enabled`)"
					>
						<font-awesome-icon :icon="['fas', 'sort']"></font-awesome-icon>
					</ej-button>

					<ej-button
						@click="filteringToggle"
						:class="{ active: filteringOpened }"
						v-if="_.get($store.state, `${module}.filtering.enabled`)"
					>
						<font-awesome-icon :icon="['fas', 'filter']"></font-awesome-icon>
					</ej-button>
				</ej-buttons>
			</ej-toolbar>
		</ej-mobile-header>

		<ej-content>
			<ej-breadcrumb>
				<li class="breadcrumb-item">
					{{ $route.meta.title }}
				</li>
			</ej-breadcrumb>

			<div class="header">
				<div class="container-fluid">
					<div class="row">
						<div class="col-lg-7">
							<div :class="{ row: true, 'mobile-hidden': filteringMobileHidden }">
								<h4 class="col-12">Filtros</h4>

								<div class="form-group col-lg-4 col-12">
									<label>Campo:</label>
									<multiselect
										v-model="filteringField"
										:options="$store.state[module].filtering.fields"
										:searchable="true"
										:close-on-select="true"
										:show-labels="false"
										placeholder=""
										track-by="value"
										label="name"
									></multiselect>
								</div>

								<div class="form-group col-lg-4 col-12">
									<label>Critério:</label>
									<multiselect
										v-model="filteringCriteria"
										:options="$store.state[module].filtering.criterias"
										:searchable="true"
										:close-on-select="true"
										:show-labels="false"
										placeholder=""
										track-by="value"
										label="name"
									></multiselect>
								</div>

								<div class="form-group col-lg-4 col-12">
									<label>Pesquisar por:</label>
									<input type="text" class="form-control" v-model="filteringKeyword">
								</div>
							</div>
						</div>

						<div class="col-lg-5">
							<div :class="{ row: true, 'mobile-hidden': sortingMobileHidden }">
								<h4 class="col-12">Ordenação</h4>

								<div class="form-group col-lg-6 col-12">
									<label>Campo:</label>
									<multiselect
										v-model="sortingField"
										:options="$store.state[module].sorting.fields"
										:searchable="true"
										:close-on-select="true"
										:show-labels="false"
										placeholder=""
										track-by="value"
										label="name"
									></multiselect>
								</div>

								<div class="form-group col-lg-6 col-12">
									<label>Ordem:</label>
									<multiselect
										v-model="sortingDirection"
										:options="$store.state[module].sorting.directions"
										:searchable="true"
										:close-on-select="true"
										:show-labels="false"
										placeholder=""
										track-by="value"
										label="name"
									></multiselect>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<main class="content">
				<div class="d-flex justify-content-center" v-if="$store.state.orders.fetching">
					<div class="default-spinner">
						<div class="default-spinner-inner">
							<div></div>
							<div></div>
						</div>
					</div>
				</div>
				<div v-else>
					<div class="container-fluid">
						<div class="row">
							<div class="col">
								<table class="desktop-list-content">
									<thead>
										<tr>
											<th class="sortable" @click="setOrder('orders.id')">
												Id <ej-order-icon :module="module" :field="'orders.id'"></ej-order-icon>
											</th>
											<th class="sortable" @click="setOrder('customer.name')">
												Cliente <ej-order-icon :module="module" :field="'customer.name'"></ej-order-icon>
											</th>
											<th class="sortable" @click="setOrder('city.name')">
												Cidade/UF <ej-order-icon :module="module" :field="'city.name'"></ej-order-icon>
											</th>
											<th class="sortable" @click="setOrder('seller.name')">
												Emitente <ej-order-icon :module="module" :field="'seller.name'"></ej-order-icon>
											</th>
											<th class="sortable" @click="setOrder('reseller.name')">
												Revenda <ej-order-icon :module="module" :field="'reseller.name'"></ej-order-icon>
											</th>
											<th class="sortable" @click="setOrder('orders.status')">
												Status <ej-order-icon :module="module" :field="'orders.status'"></ej-order-icon>
											</th>
											<th class="sortable" @click="setOrder('orders.meta.serie')">
												Série <ej-order-icon :module="module" :field="'orders.meta.serie'"></ej-order-icon>
											</th>
											<th class="sortable" @click="setOrder('orders.date')">
												Data <ej-order-icon :module="module" :field="'orders.date'"></ej-order-icon>
											</th>
											<th class="sortable" @click="setOrder('orders.total')">
												Total <ej-order-icon :module="module" :field="'orders.total'"></ej-order-icon>
											</th>
											<th></th>
										</tr>
									</thead>
									<tbody>
										<tr :class="order.status" :key="index" v-for="(order, index) in $store.state.orders.orders">
											<td>{{ order.id }}</td>
											<td>{{ order.customer.name }}</td>
											<td>{{ _.get(order.customer,`city`) ? `${order.customer.city.name}/${order.customer.city.state.acronym}` : '' }}</td>
											<td>{{ order.seller.name }}</td>
											<td>{{ _.get(order.reseller,`name`) }}</td>
											<td>{{ translateStatus(order.status) }}</td>
											<td>{{ _.get(order.meta,`serie`)  }}</td>
											<td>{{ translateDate(order.date, 'DD/MM/YYYY') }}</td>
											<td>
												{{ toCurrency(order.total) }}
											</td>
											<td>
												<div class="d-flex align-items-center">
													<div class="dropdown mr-2">
														<button class="btn btn-primary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
															Ações
														</button>
														<div class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
                                                            <template v-if="$store.state.auth.user.id == 1112 && order.seller_id != 1112/*mauro*/">
                                                                <a
                                                                    class="dropdown-item"
                                                                    :href="`${api_url}/orders/${order.id}/export/html`"
                                                                    target="_blank"
                                                                >
                                                                    <font-awesome-icon :icon="['far', 'eye']"></font-awesome-icon> Ver <!-- todo pdf? -->
                                                                </a>
                                                            </template>
                                                            <template v-else>
                                                                <button
                                                                    class="dropdown-item"
                                                                    @click="cancelOrder(order.id, index)"
                                                                    v-if="(
                                                                            $store.state.auth.permissions['cancel_orders_for_anyone']
                                                                            || $store.state.auth.permissions['cancel_budget_or_new_orders_for_anyone']
                                                                            || $store.state.auth.permissions['cancel_budget_or_new_orders_for_yourself']
                                                                        )
                                                                        && order.status !== 'canceled'
                                                                    "
                                                                >
                                                                    <font-awesome-icon :icon="['fas', 'window-close']"></font-awesome-icon> Cancelar
                                                                </button>

                                                                <button
                                                                    class="dropdown-item"
                                                                    v-if="
                                                                    $store.state.auth.permissions['view_any_order']
                                                                    || $store.state.auth.permissions['view_your_own_orders']
                                                                "
                                                                    @click="sendEmailCopy(order)"
                                                                >
                                                                    <font-awesome-icon :icon="['far', 'envelope']"></font-awesome-icon> Compartilhar
                                                                </button>

                                                                <a
                                                                    class="dropdown-item"
                                                                    :href="shareOrderLink(order)"
                                                                    target="_blank"
                                                                    v-if="
                                                                    $store.state.auth.permissions['view_any_order']
                                                                    || $store.state.auth.permissions['view_your_own_orders']
                                                                "
                                                                >
                                                                    <font-awesome-icon :icon="['fab', 'whatsapp']"></font-awesome-icon> Compartilhar
                                                                </a>

                                                                <router-link
                                                                    class="dropdown-item"
                                                                    :to="{name: 'orders.edit', params: {id: order.id}}"
                                                                    v-if="$store.state.auth.permissions['update_orders_in_any_case']
                                                                            || $store.state.auth.permissions['update_order_data_except_status_for_anyone']
                                                                            || $store.state.auth.permissions['update_order_data_except_status_for_yourself']"
                                                                >
                                                                    <font-awesome-icon :icon="['far', 'edit']"></font-awesome-icon> Editar
                                                                </router-link>

                                                                <button
                                                                    class="dropdown-item"
                                                                    @click="deliverOrder(order.id, index)"
                                                                    v-if="(
                                                                        $store.state.auth.permissions['update_orders_in_any_case']
                                                                        || $store.state.auth.permissions['update_orders_with_status_shipped_to_delivered_for_anyone']
                                                                    )
                                                                    && order.status !== 'delivered'
                                                                "
                                                                >
                                                                    <font-awesome-icon :icon="['fas', 'truck-loading']"></font-awesome-icon> Entregar
                                                                </button>

                                                                <button
                                                                    class="dropdown-item"
                                                                    @click="deleteOrder(order.id, index)"
                                                                    v-if="
                                                                    $store.state.auth.permissions['delete_orders_in_any_case']
                                                                    || $store.state.auth.permissions['delete_budget_or_new_orders_for_yourself']
                                                                    || $store.state.auth.permissions['delete_budget_or_new_orders_for_anyone']
                                                                "
                                                                >
                                                                    <font-awesome-icon :icon="['far', 'trash-alt']"></font-awesome-icon> Excluir
                                                                </button>

                                                                <button
                                                                    class="dropdown-item"
                                                                    @click="shipOrder(order.id, index)"
                                                                    v-if="(
                                                                        $store.state.auth.permissions['update_orders_in_any_case']
                                                                        || $store.state.auth.permissions['ship_orders_in_any_case']
                                                                        || $store.state.auth.permissions['ship_orders_with_status_produced_for_anyone']
                                                                    )
                                                                    && order.status !== 'shipped'
                                                                "
                                                                >
                                                                    <font-awesome-icon :icon="['fas', 'shipping-fast']"></font-awesome-icon> Expedir
                                                                </button>

                                                                <a
                                                                    class="dropdown-item"
                                                                    :href="`${api_url}/orders/${order.id}/check`"
                                                                    v-if="$store.state.auth.permissions['view_any_order']"
                                                                    target="_blank"
                                                                >
                                                                    <font-awesome-icon :icon="['fas', 'tasks']"></font-awesome-icon> Checar Opc.
                                                                </a>

                                                                <button
                                                                    class="dropdown-item"
                                                                    @click="duplicateOrder(order.id)"
                                                                    v-if="
                                                                    $store.state.auth.permissions['view_any_order']
                                                                    || $store.state.auth.permissions['view_your_own_orders']
                                                                "
                                                                >
                                                                    <font-awesome-icon :icon="['fas', 'clone']"></font-awesome-icon> Duplicar
                                                                </button>

                                                                <button
                                                                    class="dropdown-item"
                                                                    @click="launchOrder(order.id, index)"
                                                                    v-if="(
                                                                        $store.state.auth.permissions['update_orders_in_any_case']
                                                                        || $store.state.auth.permissions['launch_orders_in_any_case']
                                                                        || $store.state.auth.permissions['launch_orders_with_status_released_for_anyone']
                                                                        || $store.state.auth.permissions['update_orders_with_status_released_to_launched_for_anyone']
                                                                    )
                                                                    && order.status !== 'launched'
                                                                "
                                                                >
                                                                    <font-awesome-icon :icon="['fas', 'rocket']"></font-awesome-icon> Lançar
                                                                </button>

                                                                <button
                                                                    class="dropdown-item"
                                                                    @click="newOrder(order.id, index)"
                                                                    v-if="(
                                                                        $store.state.auth.permissions['update_orders_in_any_case']
                                                                        || $store.state.auth.permissions['new_orders_for_anyone']
                                                                        || $store.state.auth.permissions['update_orders_with_status_budget_to_new_for_anyone']
                                                                        || $store.state.auth.permissions['update_orders_with_status_rejected_to_new_for_anyone']
                                                                        || $store.state.auth.permissions['update_orders_with_status_rejected_to_new_for_yourself']
                                                                        || $store.state.auth.permissions['update_orders_with_status_budget_to_new_for_yourself']
                                                                    )
                                                                    && order.status !== 'new'
                                                                "
                                                                >
                                                                    <font-awesome-icon :icon="['fas', 'check']"></font-awesome-icon> Novo
                                                                </button>

                                                                <button
                                                                    class="dropdown-item"
                                                                    @click="releaseOrder(order.id, index)"
                                                                    v-if="(
                                                                        $store.state.auth.permissions['update_orders_in_any_case']
                                                                        || $store.state.auth.permissions['release_orders_in_any_case']
                                                                        || $store.state.auth.permissions['release_orders_with_status_new_or_rejected_for_anyone']
                                                                    )
                                                                    && order.status !== 'released'
                                                                "
                                                                >
                                                                    <font-awesome-icon :icon="['far', 'thumbs-up']"></font-awesome-icon> Liberar
                                                                </button>

                                                                <button
                                                                    class="dropdown-item"
                                                                    @click="newOrder(order.id, index)"
                                                                    v-if="$store.state.auth.permissions['update_orders_in_any_case']"
                                                                >
                                                                    <font-awesome-icon :icon="['fas', 'check']"></font-awesome-icon> Orçamento
                                                                </button>

                                                                <a
                                                                    class="dropdown-item"
                                                                    :href="`${api_url}/orders/${order.id}/export/holma`"
                                                                    target="_blank"
                                                                    v-if="
                                                                    $store.state.auth.permissions['view_any_order']
                                                                    || $store.state.auth.permissions['view_your_own_orders']
                                                                "
                                                                >
                                                                    <font-awesome-icon :icon="['fas', 'download']"></font-awesome-icon> Planilha Holma
                                                                </a>

                                                                <button
                                                                    class="dropdown-item"
                                                                    @click="produceOrder(order.id, index)"
                                                                    v-if="(
                                                                    $store.state.auth.permissions['update_orders_in_any_case']
                                                                    || $store.state.auth.permissions['produce_orders_in_any_case']
                                                                    || $store.state.auth.permissions['produce_orders_with_status_launched_for_anyone']
                                                                )
                                                                && order.status !== 'produced'
                                                            ">
                                                                    <font-awesome-icon :icon="['fas', 'clipboard-check']"></font-awesome-icon> Produzido
                                                                </button>

                                                                <a
                                                                    class="dropdown-item"
                                                                    :href="`${api_url}/orders/${order.id}/export/pdf`"
                                                                    target="_blank"
                                                                    v-if="
                                                                    $store.state.auth.permissions['view_any_order']
                                                                    || $store.state.auth.permissions['view_your_own_orders']
                                                                "
                                                                >
                                                                    <font-awesome-icon :icon="['fas', 'file-pdf']"></font-awesome-icon> PDF
                                                                </a>

                                                                <button
                                                                    class="dropdown-item"
                                                                    @click="rejectOrder(order.id, index)"
                                                                    v-if="(
                                                                        $store.state.auth.permissions['update_orders_in_any_case']
                                                                        || $store.state.auth.permissions['reject_orders_in_any_case']
                                                                        || $store.state.auth.permissions['reject_orders_with_status_new_or_released_for_anyone']
                                                                    )
                                                                    && order.status !== 'rejected'
                                                                "
                                                                >
                                                                    <font-awesome-icon :icon="['far', 'thumbs-down']"></font-awesome-icon> Rejeitar
                                                                </button>

                                                                <button
                                                                    class="dropdown-item"
                                                                    @click="refreshOrder(order.id, index)"
                                                                    v-if="
                                                                    $store.state.auth.permissions['view_any_order']
                                                                    || $store.state.auth.permissions['view_your_own_orders']
                                                                "
                                                                >
                                                                    <font-awesome-icon :icon="['fas', 'sync-alt']"></font-awesome-icon> Resincr. Holma
                                                                </button>

                                                                <a
                                                                    class="dropdown-item"
                                                                    :href="`${api_url}/orders/${order.id}/export/html`"
                                                                    target="_blank"
                                                                    v-if="
                                                                    $store.state.auth.permissions['view_any_order']
                                                                    || $store.state.auth.permissions['view_your_own_orders']
                                                                "
                                                                >
                                                                    <font-awesome-icon :icon="['far', 'eye']"></font-awesome-icon> Ver <!-- todo pdf? -->
                                                                </a>

                                                                <a
                                                                    class="dropdown-item"
                                                                    style="color: red"
                                                                    :href="`${api_url}/orders/${order.id}/export/html?internal=true`"
                                                                    target="_blank"
                                                                    v-if="
                                                                    (
                                                                        $store.state.auth.permissions['view_any_order']
                                                                        || $store.state.auth.permissions['view_your_own_orders']
                                                                    )
                                                                    && $store.state.auth.permissions['view_ps_internal']
                                                                "
                                                                >
                                                                    <font-awesome-icon :icon="['far', 'eye']"></font-awesome-icon> Ver (interno)
                                                                </a>
                                                            </template>
														</div>
													</div>

													<font-awesome-icon class="mr-1 order-unpaid" v-if="!_.get(order.meta,'paid')" :icon="['fas', 'business-time']" v-tooltip="'Ainda não quitado pelo cliente'"></font-awesome-icon>

                                                    <!-- Se for uma revenda e não foi contiver data de pagamento da comissão -->
                                                    <font-awesome-icon
                                                        v-if="_.get(order, 'reseller.id') && !_.get(order, 'meta.comissoes.revenda.payment_date', false)"
                                                        class="mr-1 comission-unpaid"
                                                        :icon="['fas', 'user-clock']"
                                                        v-tooltip="'Comissão pendente à revenda'"
                                                    ></font-awesome-icon>

                                                    <!-- Se contiver um promotor e não foi contiver data de pagamento da comissão -->
                                                    <font-awesome-icon
                                                        v-if="_.get(order, 'indicator.id') && !_.get(order, 'meta.comissoes.promotor.payment_date', false)"
                                                        class="mr-1 comission-unpaid"
                                                        :icon="['fas', 'user-clock']"
                                                        v-tooltip="'Comissão pendente ao promotor'"
                                                    ></font-awesome-icon>
												</div>
											</td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>

						<div class="mobile-list-content">
							<div class="row">
								<div class="col-12 col-md-6" :key="index" v-for="(order, index) in $store.state.orders.orders">
									<div class="card">
										<div class="card-body">
											<h5 class="card-title"><strong>{{ order.id }}</strong> - {{ order.customer.name }}</h5>
											<p class="card-text"><strong>Cidade:</strong> {{ _.get(`order.customer`, 'city') ? `${order.customer.city.name}/${order.customer.city.state.acronym}` : '' }}</p>
											<p class="card-text"><strong>Emitente:</strong> {{ order.seller.name }}</p>
											<p class="card-text"><strong>Revenda:</strong> {{ _.get(order.reseller,`name`) }}</p>
											<p class="card-text"><strong>Status:</strong> {{ translateStatus(order.status) }}</p>
											<p class="card-text"><strong>Total:</strong> {{ toCurrency(order.total) }}</p>

											<font-awesome-icon class="mr-1 order-unpaid" v-if="!_.get(order.meta,'paid')" :icon="['fas', 'business-time']" v-tooltip="'Ainda não quitado pelo cliente'"></font-awesome-icon>

                                            <!-- Se for uma revenda e não foi contiver data de pagamento da comissão -->
                                            <font-awesome-icon
                                                v-if="_.get(order, 'reseller.id') && !_.get(order, 'meta.comissoes.revenda.payment_date', false)"
                                                class="mr-1 comission-unpaid"
                                                :icon="['fas', 'user-clock']"
                                                v-tooltip="'Comissão pendente à revenda'"
                                            ></font-awesome-icon>

                                            <!-- Se contiver um promotor e não foi contiver data de pagamento da comissão -->
                                            <font-awesome-icon
                                                v-if="_.get(order, 'indicator.id') && !_.get(order, 'meta.comissoes.promotor.payment_date', false)"
                                                class="mr-1 comission-unpaid"
                                                :icon="['fas', 'user-clock']"
                                                v-tooltip="'Comissão pendente ao promotor'"
                                            ></font-awesome-icon>

											<div class="actions">
												<button
													class="btn btn-primary"
													@click="refreshOrder(order.id, index)"
													v-if="
														$store.state.auth.permissions['update_orders_in_any_case']
														|| $store.state.auth.permissions['update_order_data_except_status_for_anyone']
														|| $store.state.auth.permissions['update_order_data_except_status_for_yourself']
													"
												>
													<font-awesome-icon :icon="['fas', 'sync-alt']"></font-awesome-icon>
												</button>

												<button
													class="btn btn-primary"
													@click="cancelOrder(order.id, index)"
													v-if="(
															$store.state.auth.permissions['cancel_orders_for_anyone']
															|| $store.state.auth.permissions['cancel_budget_or_new_orders_for_anyone']
															|| $store.state.auth.permissions['cancel_budget_or_new_orders_for_yourself']
														)
														&& order.status !== 'canceled'
													"
												>
													<font-awesome-icon :icon="['fas', 'window-close']"></font-awesome-icon>
												</button>

												<a
													class="btn btn-primary"
													:href="shareOrderLink(order)"
													target="_blank"
													v-if="
														$store.state.auth.permissions['view_any_order']
														|| $store.state.auth.permissions['view_your_own_orders']
													"
												>
													<font-awesome-icon :icon="['fab', 'whatsapp']"></font-awesome-icon>
												</a>

												<button
													class="btn btn-primary"
                                                    @click="sendEmailCopy(order)"
													v-if="
														$store.state.auth.permissions['view_any_order']
														|| $store.state.auth.permissions['view_your_own_orders']
													"
												>
													<font-awesome-icon :icon="['far', 'envelope']"></font-awesome-icon>
												</button>

												<a
													class="btn btn-primary"
													:href="`${api_url}/orders/${order.id}/export/holma`"
													target="_blank"
													v-if="
														$store.state.auth.permissions['view_any_order']
														|| $store.state.auth.permissions['view_your_own_orders']
													"
												>
													<font-awesome-icon :icon="['fas', 'download']"></font-awesome-icon>
												</a>

												<router-link
													class="btn btn-primary"
													:to="{name: 'orders.edit', params: {id: order.id}}"
													v-if="
														$store.state.auth.permissions['update_order_data_except_status_for_anyone']
														|| $store.state.auth.permissions['update_order_data_except_status_for_yourself']
													"
												>
													<font-awesome-icon :icon="['far', 'edit']"></font-awesome-icon>
												</router-link>

												<button
													class="btn btn-primary"
													@click="deliverOrder(order.id, index)"
													v-if="(
															$store.state.auth.permissions['update_orders_in_any_case']
															|| $store.state.auth.permissions['update_orders_with_status_shipped_to_delivered_for_anyone']
														)
														&& order.status !== 'delivered'
													"
												>
													<font-awesome-icon :icon="['fas', 'truck-loading']"></font-awesome-icon>
												</button>

												<button
													class="btn btn-primary"
													@click="deleteOrder(order.id, index)"
													v-if="
														$store.state.auth.permissions['delete_orders_in_any_case']
														|| $store.state.auth.permissions['delete_budget_or_new_orders_for_yourself']
														|| $store.state.auth.permissions['delete_budget_or_new_orders_for_anyone']
													"
												>
													<font-awesome-icon :icon="['far', 'trash-alt']"></font-awesome-icon>
												</button>

												<button
													class="btn btn-primary"
													@click="shipOrder(order.id, index)"
													v-if="(
															$store.state.auth.permissions['update_orders_in_any_case']
															|| $store.state.auth.permissions['ship_orders_in_any_case']
															|| $store.state.auth.permissions['ship_orders_with_status_produced_for_anyone']
														)
														&& order.status !== 'shipped'
													"
												>
													<font-awesome-icon :icon="['fas', 'shipping-fast']"></font-awesome-icon>
												</button>

												<button
													class="btn btn-primary"
													@click="launchOrder(order.id, index)"
													v-if="(
															$store.state.auth.permissions['update_orders_in_any_case']
															|| $store.state.auth.permissions['launch_orders_in_any_case']
															|| $store.state.auth.permissions['launch_orders_with_status_released_for_anyone']
															|| $store.state.auth.permissions['update_orders_with_status_released_to_launched_for_anyone']
														)
														&& order.status !== 'launched'
													"
												>
													<font-awesome-icon :icon="['fas', 'rocket']"></font-awesome-icon>
												</button>

												<button
													class="btn btn-primary"
													@click="releaseOrder(order.id, index)"
													v-if="(
															$store.state.auth.permissions['update_orders_in_any_case']
															|| $store.state.auth.permissions['release_orders_in_any_case']
															|| $store.state.auth.permissions['release_orders_with_status_new_or_rejected_for_anyone']
														)
														&& order.status !== 'released'
													"
												>
													<font-awesome-icon :icon="['far', 'thumbs-up']"></font-awesome-icon>
												</button>

												<button
													class="btn btn-primary"
													@click="newOrder(order.id, index)"
													v-if="(
															$store.state.auth.permissions['update_orders_in_any_case']
															|| $store.state.auth.permissions['new_orders_for_anyone']
															|| $store.state.auth.permissions['update_orders_with_status_budget_to_new_for_anyone']
															|| $store.state.auth.permissions['update_orders_with_status_rejected_to_new_for_anyone']
															|| $store.state.auth.permissions['update_orders_with_status_rejected_to_new_for_yourself']
															|| $store.state.auth.permissions['update_orders_with_status_budget_to_new_for_yourself']
														)
														&& order.status !== 'new'
													"
												>
													<font-awesome-icon :icon="['fas', 'check']"></font-awesome-icon>
												</button>

												<button
													class="btn btn-primary"
													@click="produceOrder(order.id, index)"
													v-if="(
															$store.state.auth.permissions['update_orders_in_any_case']
															|| $store.state.auth.permissions['produce_orders_in_any_case']
															|| $store.state.auth.permissions['produce_orders_with_status_launched_for_anyone']
														)
														&& order.status !== 'produced'
													">
													<font-awesome-icon :icon="['fas', 'clipboard-check']"></font-awesome-icon>
												</button>

												<button
													class="btn btn-primary"
													@click="rejectOrder(order.id, index)"
													v-if="(
															$store.state.auth.permissions['update_orders_in_any_case']
															|| $store.state.auth.permissions['reject_orders_in_any_case']
															|| $store.state.auth.permissions['reject_orders_with_status_new_or_released_for_anyone']
														)
														&& order.status !== 'rejected'
													"
												>
													<font-awesome-icon :icon="['far', 'thumbs-down']"></font-awesome-icon>
												</button>

												<a
													class="btn btn-primary"
													:href="`${api_url}/orders/${order.id}/export/html`"
													target="_blank"
													v-if="
														$store.state.auth.permissions['view_any_order']
														|| $store.state.auth.permissions['view_your_own_orders']
													"
												>
													<font-awesome-icon :icon="['far', 'eye']"></font-awesome-icon>
												</a>

												<a
													class="btn btn-primary"
													:href="`${api_url}/orders/${order.id}/export/html?internal=true`"
													target="_blank"
													v-if="
														(
                                                            $store.state.auth.permissions['view_any_order']
                                                            || $store.state.auth.permissions['view_your_own_orders']
														)
                                                        && $store.state.auth.permissions['view_ps_internal']
													"
												>
													<font-awesome-icon style="color: red" :icon="['far', 'eye']"></font-awesome-icon>
												</a>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>

					<div class="container-fluid">
						<div class="row">
							<div class="col d-flex justify-content-center">
								<nav class="pagination-nav" aria-label="Page navigation example">
									<ul class="pagination">
										<li class="page-item" v-if="$store.state.orders.pagination.current_page > 1">
											<button class="page-link" @click="setPage($store.state.orders.pagination.current_page-1)">Anterior</button>
										</li>
										<li :class="{ 'page-item':true, active: page === $store.state.orders.pagination.current_page}" :key="index" v-for="(page, index) in $store.state.orders.pagination.last_page">
											<button class="page-link" @click="setPage(page)">{{ page }}</button>
										</li>
										<li class="page-item" v-if="$store.state.orders.pagination.current_page < $store.state.orders.pagination.last_page">
											<button class="page-link" @click="setPage($store.state.orders.pagination.current_page+1)">Próxima</button>
										</li>
									</ul>
								</nav>
							</div>
						</div>
					</div>
				</div>
			</main>

			<ej-floating-button
                v-if="$store.state.auth.permissions['create_orders_for_anyone'] || $store.state.auth.permissions['create_orders_for_yourself']"
                @click="$router.push({name: 'orders.create'})"
            >
				<font-awesome-icon :icon="['fas', 'plus']"></font-awesome-icon>
			</ej-floating-button>
		</ej-content>
	</ej-page>
</template>

<script>
//Components
import Multiselect from 'vue-multiselect';

//Mixins
import SortingMixin from "@/mixins/SortingMixin";
import FilteringMixin from "@/mixins/FilteringMixin";
import PaginationMixin from "@/mixins/PaginationMixin";
import DateMixin from "@/mixins/DateMixin";
import FetchingMixin from "@/mixins/FetchingMixin";
import CurrencyMixin from "@/mixins/CurrencyMixin";

//Aux
import {customWatch, exibeErro, emailOrderShareLink, whatsappOrderShareLink} from "@/helpers";
import Swal from "sweetalert2";

export default {
	data() {
		return {
			module: 'orders'
		}
	},

	computed: {
		api_url() {
			return process.env.VUE_APP_API_URL;
		}
	},

	mixins: [
		FilteringMixin,
		SortingMixin,
		PaginationMixin,
		DateMixin,
		FetchingMixin,
		CurrencyMixin
	],

	watch: {
		"$store.state.orders.sorting.selected": customWatch,
		"$store.state.orders.filtering.selected": customWatch,
		"$store.state.orders.filtering.keyword": customWatch,
		"$store.state.orders.pagination.current_page": customWatch,
	},

	methods: {
		shareOrderLink: whatsappOrderShareLink,
		emailOrderShareLink: emailOrderShareLink,

        async sendEmailCopy(order) {
            const vm = this;
            await Swal.fire({
                title: 'Para quais pessoas?',
                html:
                    '<div style="text-align: left">' +
                        '<div><input type="checkbox" id="swal2-checkbox1" value="customer"><label for="swal2-checkbox1"> Cliente</label></div>' +
                        '<div><input type="checkbox" id="swal2-checkbox2" value="reseller"><label for="swal2-checkbox2"> Revenda</label></div>' +
                        '<div><input type="checkbox" id="swal2-checkbox3" value="seller"><label for="swal2-checkbox3"> Representante</label></div>' +
                    '</div>',
                confirmButtonText: 'Enviar',
                cancelButtonText: 'Cancelar',
                focusConfirm: false,
                showLoaderOnConfirm: true,
                preConfirm: () => {
                    return vm.$store.dispatch(`orders/send_email_copy`, {
                        id: order.id,
                        data: {
                            customer: document.getElementById('swal2-checkbox1').checked,
                            reseller: document.getElementById('swal2-checkbox2').checked,
                            seller: document.getElementById('swal2-checkbox3').checked,
                        }
                    }).then(function() {
                        Swal.fire('E-mail enviado!','','success');
                    }).catch(function(e) {
                        exibeErro(e);
                    });
                }
            });
        },

		translateType(type) {
			switch (type) {
				case 'cabin':
					return 'Cabina';

				case 'other':
					return 'Outro';
			}
		},

		refreshOrder(id, index) {
			const vm = this;

			Swal.fire({
				title: 'Confirmação',
				text: 'Você tem certeza que deseja buscar atualizações deste pedido, no Holma?',
				icon: 'warning',
				confirmButtonText: 'Sim, atualizar',
				confirmButtonColor: '#d33',
				showCancelButton: true,
			}).then(function(result) {
				if (result.isConfirmed)
					vm.$store.dispatch(`orders/refresh`, id)
						.then(function(response) {
							Swal.fire(
								'Sucesso!',
								'Pedido atualizado com sucesso!',
								'success'
							).then(() => {
								vm.$set(vm.$store.state.orders.orders, index, response.data);
							});
						})
						.catch(function (error) {
							// handle error
							exibeErro(error);
						})
						.then(function () {
							// always executed
						});
			});
		},

		cancelOrder(id, index) {
			const vm = this;

			Swal.fire({
				title: 'Confirmação',
				text: 'Você tem certeza que deseja cancelar esse pedido?',
				icon: 'warning',
				confirmButtonText: 'Sim, cancelar',
				confirmButtonColor: '#d33',
				showCancelButton: true,
			}).then(function(result) {
				if (result.isConfirmed)
					vm.$store.dispatch(`orders/cancel`, id)
						.then(function() {
							Swal.fire(
								'Sucesso!',
								'Pedido cancelado com sucesso!',
								'success'
							).then(() => {
								vm.$set(vm.$store.state.orders.orders[index],'status','canceled');
							});
						})
						.catch(function (error) {
							// handle error
							exibeErro(error);
						})
						.then(function () {
							// always executed
						});
			});
		},

        duplicateOrder(id) {
            const vm = this;

            Swal.fire({
                title: 'Confirmação',
                text: 'Você tem certeza que deseja duplicar esse pedido?',
                icon: 'warning',
                confirmButtonText: 'Sim, cancelar',
                confirmButtonColor: '#d33',
                showCancelButton: true,
            }).then(function(result) {
                if (result.isConfirmed)
                    vm.$store.dispatch(`orders/duplicate`, id)
                        .then(function() {
                            Swal.fire(
                                'Sucesso!',
                                'Pedido duplicado com sucesso!',
                                'success'
                            ).then(() => {
                                //window.location.reload();
                                vm.requestData();
                            });
                        })
                        .catch(function (error) {
                            // handle error
                            exibeErro(error);
                        })
                        .then(function () {
                            // always executed
                        });
            });
        },

		launchOrder(id, index) {
			const vm = this;

			Swal.fire({
				title: 'Confirmação',
				text: 'Você tem certeza que deseja lançar esse pedido?',
				icon: 'warning',
				confirmButtonText: 'Sim, lançar',
				confirmButtonColor: '#d33',
				showCancelButton: true,
			}).then(function(result) {
				if (result.isConfirmed)
					vm.$store.dispatch(`orders/launch`, id)
						.then(function() {
							Swal.fire(
								'Sucesso!',
								'Pedido lançado com sucesso!',
								'success'
							).then(() => {
								vm.$set(vm.$store.state.orders.orders[index],'status','launched');
							});
						})
						.catch(function (error) {
							// handle error
							exibeErro(error);
						})
						.then(function () {
							// always executed
						});
			});
		},

		deliverOrder(id, index) {
			const vm = this;

			Swal.fire({
				title: 'Confirmação',
				text: 'Você tem certeza que deseja entregar esse pedido?',
				icon: 'warning',
				confirmButtonText: 'Sim, entregar',
				confirmButtonColor: '#d33',
				showCancelButton: true,
			}).then(function(result) {
				if (result.isConfirmed)
					vm.$store.dispatch(`orders/deliver`, id)
						.then(function() {
							Swal.fire(
								'Sucesso!',
								'Pedido entregue com sucesso!',
								'success'
							).then(() => {
								vm.$set(vm.$store.state.orders.orders[index],'status','delivered');
							});
						})
						.catch(function (error) {
							// handle error
							exibeErro(error);
						})
						.then(function () {
							// always executed
						});
			});
		},

		produceOrder(id, index) {
			const vm = this;

			Swal.fire({
				title: 'Confirmação',
				text: 'Você tem certeza que deseja alterar o estado desse pedido para produzido?',
				icon: 'warning',
				confirmButtonText: 'Sim',
				confirmButtonColor: '#d33',
				showCancelButton: true,
			}).then(function(result) {
				if (result.isConfirmed)
					vm.$store.dispatch(`orders/produce`, id)
						.then(function() {
							Swal.fire(
								'Sucesso!',
								'Pedido produzido com sucesso!',
								'success'
							).then(() => {
								vm.$set(vm.$store.state.orders.orders[index],'status','produced');
							});
						})
						.catch(function (error) {
							// handle error
							exibeErro(error);
						})
						.then(function () {
							// always executed
						});
			});
		},

		releaseOrder(id, index) {
			const vm = this;

			Swal.fire({
				title: 'Confirmação',
				text: 'Você tem certeza que deseja liberar esse pedido?',
				icon: 'warning',
				confirmButtonText: 'Sim, liberar',
				confirmButtonColor: '#d33',
				showCancelButton: true,
			}).then(function(result) {
				if (result.isConfirmed)
					vm.$store.dispatch(`orders/release`, id)
						.then(function() {
							Swal.fire(
								'Sucesso!',
								'Pedido liberado com sucesso!',
								'success'
							).then(() => {
								vm.$set(vm.$store.state.orders.orders[index],'status','released');
							});
						})
						.catch(function (error) {
							// handle error
							exibeErro(error);
						})
						.then(function () {
							// always executed
						});
			});
		},

		newOrder(id, index) {
			const vm = this;

			Swal.fire({
				title: 'Confirmação',
				text: 'Você tem certeza que alterar o estado deste pedido/orçamento para novo?',
				icon: 'warning',
				confirmButtonText: 'Sim',
				confirmButtonColor: '#d33',
				showCancelButton: true,
			}).then(function(result) {
				if (result.isConfirmed)
					vm.$store.dispatch(`orders/new`, id)
						.then(function() {
							Swal.fire(
								'Sucesso!',
								'Pedido alterado com sucesso!',
								'success'
							).then(() => {
								vm.$set(vm.$store.state.orders.orders[index],'status','new');
							});
						})
						.catch(function (error) {
							// handle error
							exibeErro(error);
						})
						.then(function () {
							// always executed
						});
			});
		},

		rejectOrder(id, index) {
			const vm = this;

			Swal.fire({
				title: 'Confirmação',
				text: 'Você tem certeza que deseja rejeitar esse pedido?',
				icon: 'warning',
				confirmButtonText: 'Sim, rejeitar',
				confirmButtonColor: '#d33',
				showCancelButton: true,
			}).then(function(result) {
				if (result.isConfirmed)
					vm.$store.dispatch(`orders/reject`, id)
						.then(function() {
							Swal.fire(
								'Sucesso!',
								'Pedido rejeitado com sucesso!',
								'success'
							).then(() => {
								vm.$set(vm.$store.state.orders.orders[index],'status','rejected');
							});
						})
						.catch(function (error) {
							// handle error
							exibeErro(error);
						})
						.then(function () {
							// always executed
						});
			});
		},

		deleteOrder(id, index) {
			const vm = this;

			Swal.fire({
				title: 'Confirmação',
				text: 'Você tem certeza que deseja excluir esse pedido?',
				icon: 'warning',
				confirmButtonText: 'Sim, excluir',
				confirmButtonColor: '#d33',
				showCancelButton: true,
			}).then(function(result) {
				if (result.isConfirmed)
					vm.$store.dispatch(`orders/delete`, id)
						.then(function() {
							Swal.fire(
								'Sucesso!',
								'Pedido excluído com sucesso!',
								'success'
							).then(() => {
								vm.$delete(vm.$store.state.orders.orders, index);
							});
						})
						.catch(function (error) {
							// handle error
							exibeErro(error);
						})
						.then(function () {
							// always executed
						});
			});
		},

		shipOrder(id, index) {
			const vm = this;

			Swal.fire({
				title: 'Confirmação',
				text: 'Você tem certeza que deseja expedir esse pedido?',
				icon: 'warning',
				confirmButtonText: 'Sim, expedir',
				confirmButtonColor: '#d33',
				showCancelButton: true,
			}).then(function(result) {
				if (result.isConfirmed)
					vm.$store.dispatch(`orders/ship`, id)
						.then(function() {
							Swal.fire(
								'Sucesso!',
								'Pedido expedido com sucesso!',
								'success'
							).then(() => {
								vm.$set(vm.$store.state.orders.orders[index],'status','shipped');
							});
						})
						.catch(function (error) {
							// handle error
							exibeErro(error);
						})
						.then(function () {
							// always executed
						});
			});
		},

		translateStatus(status) {
			switch (status) {
				case 'new':
					return 'NOVO';

				case 'launched':
					return 'LANÇADO';

				case 'released':
					return 'LIBERADO';

				case 'rejected':
					return 'REJEITADO';

				case 'canceled':
					return 'CANCELADO';

				case 'budget':
					return 'ORÇAMENTO';

				case 'delivered':
					return 'ENTREGUE';

				case 'shipped':
					return 'EXPEDIDO';

				case 'produced':
					return 'PRODUZIDO';
			}
		}
	},

	mounted() {
		//Fetch inicial
		this.requestData();
	},

	components: {
		Multiselect,
	},
}
</script>

<style scoped>
.desktop-list-content .new {
	color: #4b4bcc;
}

.desktop-list-content .canceled {
	color: #e63a3a;
}

.desktop-list-content .rejected {
	color: #b11515;
}

.desktop-list-content .released {
	color: #82de52;
}

.desktop-list-content .delivered {
	color: #27c4a4;
}

.desktop-list-content .produced {
	color: forestgreen;
}

.desktop-list-content .budget {
	color: #dedede;
}
</style>
